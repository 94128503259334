'use client'

import {HomeListDataItemsType} from '@/services/lib/types/home.type'
import 'swiper/css'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Story} from './Story'
import styles from './styles.module.scss'

export default ({storyItems, isMobile}: {storyItems: HomeListDataItemsType; isMobile: boolean}) => {
  return (
    <>
      {isMobile ? (
        storyItems.map((story) => <Story {...story} />)
      ) : (
        <Swiper slidesPerView={10} className={styles['swiper']}>
          {storyItems.map((story) => (
            <SwiperSlide key={story.title} className={styles['swiper-slide-desktop']}>
              <Story {...story} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  )
}
