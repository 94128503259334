'use client'

import {pushDefultPageView} from '@/services/dataLayer'
import {usePathname} from 'next/navigation'
import {useEffect, useState} from 'react'

const PageProvider = () => {
  const pathname = usePathname()
  const [datalayerPushed, setDatalayerPushed] = useState(false)

  useEffect(() => {
    if (pathname && !datalayerPushed) {
      pushDefultPageView({
        pageType: 'home',
        pageUri: pathname as string,
      })
      setDatalayerPushed(true)
    }
  }, [pathname])

  return <></>
}

export default PageProvider
