'use client'

import {HomeListDataType} from '@/services/lib/types/home.type'
import {useRouter} from 'next/navigation'

// components
import {Container} from '@/components/container/Container'
import {Grid, Link, Typography} from '@mui/material'
import BaseImage from 'tofisa-package/dist/components/Images/BaseImage'
import Banner from '../banner'
import Stories from '../stories'

// styles
import {BoxItemsStack} from './styles'

export default ({item}: {item: HomeListDataType}) => {
  const router = useRouter()

  return (
    <Container>
      {item.type == 'story' ? (
        <div className='homeStoryBase d-flex pt-3'>
          <Stories storyItems={item.items} isMobile={false} />
        </div>
      ) : item.type == 'banner' ? (
        <Banner bannerItems={item.items} />
      ) : (
        <Grid container spacing={1}>
          {item.items?.map(({title, link, media}: any) => (
            <Grid item key={title} xs={3} onClick={() => router.push(link)}>
              <Link href={link}>
                <BoxItemsStack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  spacing={2}
                >
                  <BaseImage logo={media} inpImg='boxImgClass lazyload' />
                  <Typography variant='body1'>{title}</Typography>
                </BoxItemsStack>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  )
}
