'use client'

import {Stack} from '@mui/material'
import Link from 'next/link'
import React from 'react'
import Colors from '@theme/tofisaColorPalette'
import BaseImage from 'tofisa-package/dist/components/Images/BaseImage'
import TextComponent from 'tofisa-package/dist/components/TextComponent/TextComponent'
import {HomeListDataItemsType} from '@/services/lib/types/home.type'
import {useRouter} from 'next/navigation'

export const Box = ({homeBox}: {homeBox: HomeListDataItemsType}) => {
  const router = useRouter()

  return (
    <div className='d-flex'>
      {homeBox?.map(({link, media, title}) => (
        <div key={title} className='col-4 col-md-3' onClick={() => router.push(link)}>
          <Link href={link}>
            <Stack
              sx={{backgroundColor: Colors.customeColor.commonBackground}}
              className={`me-1 perBoxDetail h-100`}
            >
              <BaseImage logo={media} baseClass='text-end' inpImg='homeBoxImgClass lazyload' />
              <TextComponent
                txtComponent={title}
                txtClass='col-11 perBoxTitleClass body-semibold lazyload'
              />
            </Stack>
          </Link>
        </div>
      ))}
    </div>
  )
}
