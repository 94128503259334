import {Typography} from '@mui/material'
import {styled} from '@mui/material/styles'

export const BoxItemsStack = styled(Typography)(() => ({
  fontSize: '0.688rem',
  fontWeight: 610,
  whiteSpace: 'break-spaces',
  color: '#333',
  minHeight: '1.563rem',
  padding: '0.313rem 0',
  textAlign: 'center',
}))

export const WebBoxItemsStack = styled(Typography)(() => ({
  fontSize: '0.888rem',
  fontWeight: 500,
  whiteSpace: 'break-spaces',
  color: '#333',
  minHeight: '1.563rem',
  padding: '0.313rem 0',
  textAlign: 'center',
}))
