import Link from 'next/link'
import BaseImage from 'tofisa-package/dist/components/Images/BaseImage'
import {BoxItemsStack, WebBoxItemsStack} from './style'
import {isMobileDevice} from '@/app/_lib/csr-helpers'

export const Story = ({link, media, title}: {link: string; media: string; title: string}) => {
  const isMobile = isMobileDevice()
  return (
    <div className='d-flex flex-column align-items-center perStorySpace iconCursor text-center'>
      <Link href={link}>
        <div
          className={` ${
            !media && 'd-flex justify-content-center align-items-center bg-background'
          }`}
        >
          {!!media ? (
            <BaseImage
              logo={media}
              inpImg={isMobile ? 'storyBase lazyload' : 'webStorySize lazyload'}
            />
          ) : (
            <BaseImage logo='/v2/Assets/Icons/NullStory.svg' alt='tofisa-emptyStory' />
          )}
        </div>
        {isMobile ? (
          <BoxItemsStack>{title ?? ''}</BoxItemsStack>
        ) : (
          <WebBoxItemsStack>{title ?? ''}</WebBoxItemsStack>
        )}
      </Link>
    </div>
  )
}
