const white = '#FFFFFF'

export default {
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  primary: {
    main: '#F26B26',
    variant: '#E26C2D',
    background: '#F8F8F8',
    surface: 'rgba(255, 255, 255, 0.87)',
    onSurfaceGray: '#E1E1E1',
    onBackground: '#323232',
  },
  error: {
    main: '#E00930',
    light: '#E00930',
    dark: '#C10628',
  },
  success: {
    main: '#8DCD03',
    light: '#8DCD03',
    dark: '#5D9405',
  },
  grey: {
    main: '#E1E1E1',
    light: '#F2F2F2',
    outline: '#EBEBEB',
    dark: '#979797',
    background: '#F9F9F9',
    divider: '#747474',
    newDark: '#a9a9a9',
  },
  text: {
    primary: '#3D3D3D',
    secondary: '#777777',
    disabled: '#BDBDBD',
  },
  customeColor: {
    commonBackground: white,
  },
}
